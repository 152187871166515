import { Routes } from '@angular/router';

export const routes: Routes = [

    {
      path: 'not-found',
      loadChildren: () =>
        import('./pages/not-found/not-found.routes')
          .then(m => m.routes)
    },
    {
      path: '',
      loadChildren: () =>
        import('./pages/home/home.routes')
          .then(m => m.routes)
          .catch(err => {
            console.error('💥 Error al cargar módulo /home:', err);
            return import('./pages/not-found/not-found.routes').then(n => n.routes);
          })
    },
    {
      path: ':slug',
      loadChildren: () =>
        import('./pages/home/home.routes')
          .then(m => m.routes)
          .catch(err => {
            console.error('💥 Error al cargar módulo dinámico (slug):', err);
            return import('./pages/not-found/not-found.routes').then(n => n.routes);
          })
    },
    {
      path: '**',
      redirectTo: 'not-found',
      pathMatch: 'full'
    }
  ];
  