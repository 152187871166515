import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { initFlowbite } from 'flowbite';

@Component({
  imports: [
    RouterModule, 
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'web-app';

  ngOnInit(): void {
    // initFlowbite();
  }
}
