export const environment = {
  production: true,
  projectId: 'rolflow-14d30',
  firestoreProjectId: 'hf2Rqu7w2q7r2uO9tjjU',
  domain: 'https://carsi.app',
  firebase: {
    apiKey: 'AIzaSyB6f6c68V0cYGMvW8pWV2D48Fj6PY5PcgI',
    authDomain: 'rolflow-14d30.firebaseapp.com',
    projectId: 'rolflow-14d30',
    storageBucket: 'rolflow-14d30.firebasestorage.app',
    messagingSenderId: '482762949423',
    appId: '1:482762949423:web:b0c06d95caeac9a0c957da',
    measurementId: 'G-4ZL4DNQSV6',
  }
};